<template>
  <disclosure-container>
    <template #form="props">
      <h1 class="form__title">
        {{ $t('DISCLOSURE_FORMS.OUTSIDE_INTEREST.TITLE') }}
      </h1>

      <form @submit.prevent="submitDisclosure(props)">
        <div>
          <label class="label" for="entityName">
            {{ $t('DISCLOSURE_FORMS.OUTSIDE_INTEREST.ENTITY_NAME') }}
          </label>
          <input
            id="entityName"
            v-model="disclosure.outsideOrganizationName"
            type="text"
            class="text-input"
            name="entityName"
            :placeholder="$t('DISCLOSURE_FORMS.TYPE_HERE')"
            autocomplete="off"
          />
        </div>

        <div>
          <label class="label" for="relatedEntity">
            {{
              $t('DISCLOSURE_FORMS.OUTSIDE_INTEREST.RELATED_ENTITY', {
                companyName: company.name,
              })
            }}
          </label>
          <s-select
            v-model="disclosure.outsideOrganizationRelationshipType"
            :options="relationshipTypes"
          />
        </div>

        <div>
          <label class="label" for="personRelationship">
            {{ $t('DISCLOSURE_FORMS.OUTSIDE_INTEREST.RELATED_PERSON') }}
          </label>
          <s-select
            v-model="disclosure.relationshipType"
            :options="outsidePersonRelationshipTypes"
          />
        </div>

        <div>
          <p class="label">
            {{ $t('DISCLOSURE_FORMS.OUTSIDE_INTEREST.INVOLVED_ENTITY') }}
          </p>

          <md-radio
            v-for="involvement in involvements"
            :key="involvement"
            v-model="disclosure.outsideOrganizationRelationshipStartedAt"
            class="eco"
            :value="involvement"
          >
            <label>
              {{
                $t(`DISCLOSURE_FORMS.OUTSIDE_INTEREST.${involvement}`, {
                  companyName: company.name,
                })
              }}
            </label>
          </md-radio>
        </div>

        <div>
          <label class="label" for="entityInvolvement">
            {{
              $t('DISCLOSURE_FORMS.OUTSIDE_INTEREST.DESCRIPTION_INVOLVEMENT')
            }}
          </label>
          <textarea
            id="entityInvolvement"
            v-model="disclosure.outsideOrganizationRelationshipDescription"
            name="entityInvolvement"
            rows="3"
            :placeholder="$t('DISCLOSURE_FORMS.TYPE_HERE')"
          />
        </div>
        <div>
          <p class="label">
            {{ $t('DISCLOSURE_FORMS.OUTSIDE_INTEREST.RECEIVE_COMPENSATION') }}
          </p>
          <div>
            <md-radio
              id="yes"
              v-model="disclosure.outsideOrganizationCompensation"
              class="eco"
              :value="true"
            >
              <label for="yes">{{ $t('DISCLOSURE_FORMS.YES') }}</label>
            </md-radio>
          </div>
          <div>
            <md-radio
              id="no"
              v-model="disclosure.outsideOrganizationCompensation"
              class="eco"
              :value="false"
            >
              <label for="no">{{ $t('DISCLOSURE_FORMS.NO') }}</label>
            </md-radio>
          </div>
        </div>
        <div>
          <p class="label">
            {{
              $t('DISCLOSURE_FORMS.OUTSIDE_INTEREST.DECISION_MAKING', {
                companyName: company.name,
              })
            }}
          </p>
          <div>
            <md-radio
              id="yes"
              v-model="disclosure.outsideOrganizationHasPower"
              class="eco"
              :value="true"
            >
              <label for="yes">{{ $t('DISCLOSURE_FORMS.YES') }}</label>
            </md-radio>
          </div>
          <div>
            <md-radio
              id="no"
              v-model="disclosure.outsideOrganizationHasPower"
              class="eco"
              :value="false"
            >
              <label for="no">{{ $t('DISCLOSURE_FORMS.NO') }}</label>
            </md-radio>
          </div>
        </div>
        <div>
          <p class="label">
            {{ $t('DISCLOSURE_FORMS.MANAGE_AWERENESS') }}
          </p>
          <div>
            <md-radio
              id="yes"
              v-model="disclosure.isManagerAware"
              class="eco"
              :value="true"
            >
              <label for="yes">
                {{ $t('DISCLOSURE_FORMS.YES') }}
              </label>
            </md-radio>
          </div>
          <div>
            <md-radio
              id="no"
              v-model="disclosure.isManagerAware"
              class="eco"
              :value="false"
            >
              <label for="no">
                <label for="no">{{ $t('DISCLOSURE_FORMS.NO') }}</label>
              </label>
            </md-radio>
          </div>
        </div>
        <div>
          <p class="label">
            {{
              $t('DISCLOSURE_FORMS.PEOPLE_PARTNER', {
                companyName: company.name,
              })
            }}
          </p>
          <div>
            <md-radio
              id="yes"
              v-model="disclosure.isHrAware"
              class="eco"
              :value="true"
            >
              <label for="yes">{{ $t('DISCLOSURE_FORMS.YES') }}</label>
            </md-radio>
          </div>
          <div>
            <md-radio
              id="no"
              v-model="disclosure.isHrAware"
              class="eco"
              :value="false"
            >
              <label for="no">{{ $t('DISCLOSURE_FORMS.NO') }}</label>
            </md-radio>
          </div>
        </div>
        <div>
          <label class="label" for="additionalComments">
            {{ $t('DISCLOSURE_FORMS.COMMENTS') }}
          </label>
          <textarea
            id="additionalComments"
            v-model="disclosure.observations"
            name="additionalComments"
            rows="3"
            :placeholder="$t('DISCLOSURE_FORMS.TYPE_HERE')"
          />
        </div>

        <div>
          <p class="description center">
            {{ $t('DISCLOSURE_FORMS.INFORMATIONS') }}
          </p>
          <s-button id="disclosure" type="submit" btn-style="eco large">
            {{ $t('DISCLOSURE_FORMS.SEND') }}
          </s-button>

          <p class="description-footer center">
            {{ $t('DISCLOSURE_FORMS.FOOTER_SAFESPACE') }}
          </p>
        </div>
      </form>
    </template>
  </disclosure-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { DisclosureOptions, Disclosures } from '@/domain/disclosure'
import DisclosureContainer from './DisclosureContainer'
import SButton from '@/components/ui/s-button'
import SSelect from '@/components/ui/s-select'

export default {
  name: 'OutsideInterest',

  components: {
    DisclosureContainer,
    SButton,
    SSelect,
  },

  data() {
    const relationshipTypes = Object.entries(
      DisclosureOptions.RelationshipTypes,
    ).map(([value, label]) => ({ value, label: `DISCLOSURE_FORMS.${label}` }))

    const outsidePersonRelationshipTypes = Object.entries(
      DisclosureOptions.OutsidePersonRelationshipTypes,
    ).map(([value, label]) => ({
      value,
      label: `DISCLOSURE_FORMS.OUTSIDE_INTEREST.${label}`,
    }))

    return {
      disclosure: { ...Disclosures.OutsideInterest },
      relationshipTypes,
      outsidePersonRelationshipTypes,
      involvements: ['PRIOR', 'WHILE', 'NOT_APPLICABLE'],
    }
  },

  computed: mapState(['company']),

  methods: {
    ...mapActions(['createDisclosure']),

    async submitDisclosure(props) {
      const params = {
        ...props,
        ...this.disclosure,
      }

      const result = await this.createDisclosure(params)

      this.disclosure = { ...Disclosures.OutsideInterest }

      const { slug, slugId } = this.company
      this.$router.push({
        name: 'company-disclosure-success',
        params: { slug, slugId, agree: true, ...result },
      })
    },
  },
}
</script>
