<template>
  <div class="disclosure-container__header">
    <div class="disclosure-container__title">
      <button class="back" @click.prevent="back">
        <img :src="backIcon" alt="Voltar" />
      </button>

      <h1>
        <strong>{{ $t('DISCLOSURE_HOME.TITLE') }}</strong>
        <span v-if="code">{{ code }}</span>
      </h1>
    </div>

    <p class="disclosure-container__help">
      {{ $t('DISCLOSURE_HOME.HELP') }}
    </p>
  </div>
</template>

<script>
import backIcon from '@/assets/images/issues/back_eco_icon.svg'

export default {
  name: 'DisclosureHeader',

  props: {
    code: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      backIcon,
    }
  },

  methods: {
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped>
.disclosure-container__header {
  text-align: center;
}
.disclosure-container__header .back {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
}
.disclosure-container__title {
  display: flex;
  align-items: center;
  margin: 25px 0 10px;
}
.disclosure-container__title h1 {
  color: var(--color-green-default);
  font-size: 23px;
  font-weight: 700;
  text-align: center;
  width: 100%;
}
.disclosure-container__title h1 span {
  font-weight: 300;
  margin-left: 5px;
  font-size: 20px;
}
.disclosure-container__help {
  margin: 30px 0 0;
  color: var(--color-grey-lighter);
  font-size: 14px;
}
</style>
