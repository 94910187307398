<template>
  <div class="s-select" @blur="open = false">
    <div class="selected" :class="{ open: open }" @click="open = !open">
      <span v-if="selected">{{ $t(selected.label) }}</span>
      <span v-else class="placeholder">
        {{ $t('REPORT_COMPANY_RELATIONSHIP_PLACEHOLDER') }}
      </span>
    </div>
    <div v-if="open" class="items">
      <div
        v-for="option of options"
        :key="option.value"
        @click.prevent="() => onSelect(option)"
      >
        {{ $t(option.label) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SSelect',

  props: {
    options: {
      type: Array,
      default: () => [],
    },
    default: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      selected: null,
      open: false,
    }
  },

  methods: {
    onSelect(option) {
      this.selected = option
      this.open = false
      this.$emit('input', option.value)
    },
  },
}
</script>

<style scoped>
.s-select {
  margin-top: 15px;
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: auto;
  line-height: 47px;
  display: block;
}

.s-select .selected {
  height: 60px;
  line-height: 47px;
  border-radius: 30px;
  border: 2px var(--color-green-default) solid;
  margin: 0;
  padding: 4px 8px;
  background-color: var(--color-white);
  padding-left: 2em;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
}

.s-select .placeholder {
  font-weight: 400;
  color: var(--color-grey-lighter);
  font-size: 14px;
}
.s-select .selected.open {
  border: 2px var(--color-green-default) solid;
  border-radius: 30px;
}

.s-select.safe-blue .selected,
.s-select.safe-blue .selected.open {
  border: 2px var(--color-blue-default) solid;
}

.s-select .selected:after {
  position: absolute;
  content: '';
  top: 28px;
  right: 2em;
  width: 0;
  height: 0;
  border: 5px solid #000;
  border-color: #000 transparent transparent transparent;
}

.s-select .items {
  color: #fff;
  border-radius: 0px 0px 10px 10px;
  overflow: hidden;
  border-right: 1px solid var(--color-green-default);
  border-left: 1px solid var(--color-green-default);
  border-bottom: 1px solid var(--color-green-default);
  position: absolute;
  background-color: var(--color-white);
  left: 0;
  right: 0;
  z-index: 1;
  width: 90%;
  margin: 0 auto;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
}

.s-select .items div {
  padding: 4px 0;
  color: #000;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.s-select .items div:hover {
  background-color: var(--color-green-light);
}
.s-select.safe-blue .items div:hover {
  background-color: var(--color-blue-light);
}
</style>
