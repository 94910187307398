const DisclosureTypes = Object.freeze({
  OUTSIDE_INTEREST: 'OUTSIDE_INTEREST',
  GIFTS_HOSPITALITY: 'GIFTS_HOSPITALITY',
  OUTSIDE_RELATIONSHIP: 'OUTSIDE_RELATIONSHIP',
  INSIDE_RELATIONSHIP: 'INSIDE_RELATIONSHIP',
  OTHER_POTENCIAL_CONFLIT_INTEREST: 'OTHER_POTENCIAL_CONFLIT_INTEREST',
})

export const DisclosureOptions = Object.freeze({
  RelationshipTypes: {
    COMPETITOR: 'COMPETITOR',
    MEMBER: 'MEMBER',
    NONE: 'NONE',
    PARTNER: 'PARTNER',
    VENDOR: 'VENDOR',
    OTHER: 'OTHER',
  },
  OutsidePersonRelationshipTypes: {
    BOARD: 'BOARD',
    CONSULTANT: 'CONSULTANT',
    DIRECTOR: 'DIRECTOR',
    EMPLOYEE: 'EMPLOYEE',
    INVESTOR: 'INVESTOR',
    OWNER: 'OWNER',
    PARTNER: 'PARTNER',
    OTHER: 'OTHER',
  },
})

const OtherPotencialConflitInterest = Object.freeze({
  type: DisclosureTypes.OTHER_POTENCIAL_CONFLIT_INTEREST,
  status: 'NEW',
  disclosureCategory: null,
  description: null,
  someoneElseInvolved: null,
  relationshipPersonName: null,
  orgName: null,
  isManagerAware: null,
  isHrAware: null,
  observations: null,
})

const OutsideInterest = Object.freeze({
  type: DisclosureTypes.OUTSIDE_INTEREST,
  status: 'NEW',
  outsideOrganizationName: null,
  outsideOrganizationRelationshipType: null,
  relationshipType: null,
  outsideOrganizationRelationshipStartedAt: null,
  outsideOrganizationRelationshipDescription: null,
  outsideOrganizationCompensation: null,
  outsideOrganizationHasPower: null,
  isManagerAware: null,
  isHrAware: null,
  observations: null,
})

const GiftsHospitality = Object.freeze({
  type: DisclosureTypes.GIFTS_HOSPITALITY,
  status: 'NEW',
  involvedPersonName: null,
  organizationName: null,
  intention: null,
  relationshipDescription: null,
  estimatedValue: null,
  recurrent: null,
  observations: null,
})

const OutsideRelationship = Object.freeze({
  type: DisclosureTypes.OUTSIDE_RELATIONSHIP,
  status: 'NEW',
  hasAuthority: null,
  isHrAware: null,
  isManagerAware: null,
  observations: null,
  outsideOrganizationDescription: null,
  outsideOrganizationName: null,
  outsideOrganizationRelationshipType: null,
  relationshipRoleDescription: null,
  relationshipPersonName: null,
  relationshipPersonPosition: null,
  relationshipType: null,
})

const InsideRelationship = Object.freeze({
  type: DisclosureTypes.INSIDE_RELATIONSHIP,
  status: 'NEW',
  isHrAware: null,
  isManagerAware: null,
  observations: null,
  relationshipPersonName: null,
  relationshipType: null,
  shareHierarchy: null,
  shareResponsabilities: null,
})

export const Disclosures = Object.freeze({
  OutsideInterest,
  GiftsHospitality,
  OutsideRelationship,
  InsideRelationship,
  OtherPotencialConflitInterest,
})
