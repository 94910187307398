<template>
  <section class="disclosure-container">
    <s-container large>
      <disclosure-header :code="code" />

      <div class="disclosure-container__form">
        <slot name="form" :code="code"></slot>
      </div>
    </s-container>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import SContainer from '@/components/ui/s-container'
import DisclosureHeader from '@/components/Disclosure/DisclosureHeader'

export default {
  name: 'DisclosureContainer',

  components: {
    SContainer,
    DisclosureHeader,
  },

  data() {
    return {
      code: null,
    }
  },

  async beforeMount() {
    if (this.$store.getters.isLoggedIn) {
      return
    }

    const { slug, slugId, agree } = this.$route.params

    if (!agree) {
      this.$router.push({ name: 'company', params: { slug, slugId } })
    }
  },

  async created() {
    this.code = await this.getNextCode()
  },

  methods: mapActions(['getNextCode']),
}
</script>

<style>
.disclosure-container {
  padding-top: 15px;
  background-color: var(--color-white);
}
.disclosure-container__instruction {
  margin: 30px 0;
  font-weight: 700;
  color: var(--color-green-default);
}
.disclosure-container__form {
  margin-top: 30px;
}
.disclosure-container__form .form__title {
  text-align: center;
  color: var(--color-green-default);
  margin-bottom: 15px;
}

.disclosure-container__form form > div {
  margin: 30px 10px;
}
.disclosure-container__form form > div:last-child,
.disclosure-container__form form > div.filebox {
  border-bottom: none;
}

.disclosure-container__form .label {
  color: var(--color-green-default);
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 15px 0;
  display: block;
}

.disclosure-container__form .text-input {
  resize: none;
  border-radius: 20px;
  background-color: transparent;
  border: 2px solid var(--color-green-default);
  font-size: 14px;
  padding: 15px 22px;
  width: 100%;
}

.disclosure-container__form textarea {
  resize: none;
  border-radius: 20px;
  background-color: transparent;
  border: 2px solid var(--color-green-default);
  font-size: 14px;
  padding: 13px 20px;
  width: 100%;
  display: block;
}
.disclosure-container__form .md-field {
  border-radius: 30px;
  border: 2px var(--color-blue-default) solid;
  border: 2px var(--color-green-default) solid;
  margin: 0 0 15px 0;
  padding: 15px 22px;
  background-color: var(--color-white);
}
.disclosure-container__form .md-menu-content.md-select-menu {
  background-color: var(--color-white);
  border-radius: 20px;
  box-shadow: 0px 5px 7px 0px rgba(112, 112, 112, 0.18);
}

.disclosure-container__form p.description {
  font-size: 13px;
}

.disclosure-container__form p.description.center {
  text-align: center;
  margin-bottom: 30px;
}

.disclosure-container__form .filebox {
  color: var(--color-green-default);
  margin-bottom: 0;
}
.disclosure-container__form p.description-footer.center {
  font-size: 13px;
  color: var(--color-grey-light);
  margin-top: 30px;
  text-align: center;
}
.md-field.md-theme-default {
  padding: 12px 22px;
  margin-top: 25px;
}
</style>
